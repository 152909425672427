.App {
  font-family: sans-serif;
  text-align: center;
  background-image: URL("./Images/background.jpg");
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
}
.Hello {
  font-family: sans-serif;
  text-align: center;
  margin-top: 5vh;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-bottom: 16vh;
  color: silver;
}
